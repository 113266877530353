@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.home_video {
    position: relative;
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: opacity .5s;
    }
    .active_video {
        opacity: 1;
    }
    .logo {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: opacity .5s;
        z-index: 4;
        background-size: 40% auto;
        width: 1000px;
        height: 450px;
    }
    .active_logo {
        opacity: 1;
        z-index: 5;
    }
    .logo_a {
        display: block;
        width: 1000px;
        height: 450px;
        &:hover {
            cursor: pointer;
        }
    }
}