@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.admin_unlock {
    @include flex(column);
    width: 100%;
    .admin_unlock_panel {
        position: relative;
        width: 1000px;
        background: $mainBackground;
        padding: 10px;
        .admin_unlock_header {
            color: $titleColor;
            font-size: 30px;
        }
        .admin_unlock_content {
            h4 {
                color: $titleColor;
                margin-bottom: 10px;
            }
            .password_input_container {
                position: relative;
                @include flex(row);
                margin-bottom: 20px;
                width: 100%;
                img {
                    position: absolute;
                    right: 10px;
                    width: 30px;
                    height: 30px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .admin_btn_container {
                @include flex(row, flex-end);
                button {
                    width: 110px;
                }
            }
        }
    }
}