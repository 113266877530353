@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.games {
    @include flex(column, flex-start);
    width: 100%;
    .games_games_container {
        @include flex(column, flex-start);
        width: 100%;
        padding-top: 50px;
        :last-child {
            margin-bottom: 0;
        }
    }
}