@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_user {
    @include flex(row, space-between);
    width: 100%;
    margin-bottom: 10px;
    h4 {
        width: 30%;
        overflow: hidden;
        margin-bottom: 0;
    }
    .admin_user_info_container {
        @include flex(row);
        width: 70%;
        .admin_user_editing_container {
            @include flex(row, flex-end);
            width: 100%;
            select {
                height: 30px;
                border-radius: 6px;
                width: 30%;
                margin-right: 5px;
            }
            .change_role_btn {
                width: 100px;
            }
        }
        .admin_user_edit_delete_container {
            @include flex(row, flex-end);
            width: 100%;
            .admin_user_role {
                text-align: end;
                margin-right: 5px;
            }
        }
        .admin_user_btn_container {
            button {
                background: $white;
                border: none;
                width: 85px;
                height: 30px;
                border-radius: 6px;
                &:hover {
                    cursor: pointer;
                    background-color: $offWhite;
                }
                &:focus {
                    outline: none;
                }
            }
            :first-child {
                margin-right: 5px;
            }
        }
        .admin_user_locked_container {
            @include flex(row);
            margin-left: 10px;
            p {
                width: 130px;
                font-size: 17px;
                color: $contentColor;
                margin-right: 5px;
            }
            button {
                background: $white;
                border: none;
                width: 85px;
                height: 30px;
                border-radius: 6px;
                &:hover {
                    cursor: pointer;
                    background-color: $offWhite;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .admin_user_editing_user_password_container {
            @include flex(row, space-between);
            width: 100%;
            .password_input {
                position: relative;
                @include flex(row);
                width: 100%;
                margin-right: 10px;
                input {
                    width: 100%;
                    height: 30px;
                    border-radius: 6px;
                }
                img {
                    position: absolute;
                    left: 90%;
                    width: 30px;
                    height: 30px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .admin_user_btn_container {
                @include flex(row)
            }
        }
    }
}