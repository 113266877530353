@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.flash_container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 3;
}

.flash_alert {
    background-color: rgba(255, 150, 50, 0.5);
}

.flash {
    width: 100%;
    height: 30px;
    max-height: 30px;
    font-size: 20px;
    color: $white;
    overflow: hidden;
    transition: max-height .4s;
    -webkit-transition: max-height .4s;
    -moz-transition: max-height .4s;

    span {
        float: right;
        margin-top: 5px;
        margin-right: 20px;
    }
}

.glyphicon {
    position: relative;
    top: -27px;
    display: inline-block;
    font-family: glyphicons halflings;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $white;
}

.glyphicon_remove {
    &::before {
        content: "\e014";
    }
    &:hover {
        cursor: pointer;
    }
}

.glyphicon_warning_sign {
    top: 1px;
    color: $errorText;
    margin-right: 3px;
    &::before {
        content: "\e107";
    }
}

.admin_sign_in {
    @include flex(column);
    width: 100%;
    h2 {
        font-size: 35px;
        margin-bottom: 10px;
        color: $white;
    }
    .admin_login_panel {
        width: 350px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        background-color: rgba(255,255,255,.4) !important;
        .panel_body {
            padding: 15px;
            width: 100%;
            &::before {
                content: " ";
                display: table;
            }
        }
    }
    .panel {
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
    .panel_default {
        border-color: $panelBorder;
    }
    .panel_danger>.panel_heading {
        color: $errorText;
        background-color: $errorBackground;
        border-color: $errorBorder;
    }
    .panel_heading {
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }
    .panel_danger {
        font-size: 13px;
        text-align: center;
        border-color: #ebccd1;
    }
    .sign_in_form {
        max-width: 400px;
        margin: 0 auto;
        color: #0a0a0a;
        width: 100%;
        input {
            margin-bottom: 5px;
        }
        .form_control {
            position: relative;
            font-size: 16px;
            height: auto;
            padding: 10px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        .password_input_container {
            @include flex(row, flex-start);
            position: relative;
            overflow: hidden;
            width: 100%;
            input {
                width: 350px;
                margin-bottom: 10px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            img {
                position: absolute;
                right: 5px;
                width: 30px;
                height: 30px;
                margin-bottom: 13px;
                z-index: 3;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .forgot_password_container {
            width: 100%;
            p {
                font-size: 15px;
                color: $anchorDarkerColor;
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
        .remember_me_container {
            input {
                margin-right: 5px;
            }
            label {
                font-weight: bold;
            }
        }
    }
    .form_control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        &:focus {
            border-color: $formFocus;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $formShadow;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $formShadow;
        }
    }
    .sign_in_btn, .reset_password_btn {
        font-size: 13px;
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: $white;
        margin-bottom: 10px;
        background-color: $btnPrimaryHover;
        border-color: $btnPrimaryBorder;
        z-index: 2;
        &:hover {
            cursor: pointer;
            background-color: $btnPrimaryBackground;
        }
    }
    .forgot_password {
        .panel_body {
            .forgot_password_form {
                position: relative;
                padding: 5px;
                max-width: 400px;
                margin: 0 auto;
                color: #0a0a0a;
                width: 100%;
                .back_arrow_container {
                    position: absolute;
                    top: -15px;
                    left: -5px;
                    p {
                        color: $anchorDarkerColor;
                        margin: 0;
                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }
                .form_control {
                    position: relative;
                    font-size: 16px;
                    height: auto;
                    padding: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}