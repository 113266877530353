@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_add_game {
    width: 100%;
    margin-bottom: 20px;
    h4 {
        color: $titleColor;
        margin-bottom: 20px;
    }
}