@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.page_footer {
    @include flex(row);
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    height: 25px;
    background-color: $mainBackground;
    box-shadow: 0 0 10px -5px $black;
    color: $greyTitle;
    text-align: center;
    z-index: 5;
    a {
        color: $anchorNormalColor;
        &:hover {
            text-decoration: underline;
        }
    }
}