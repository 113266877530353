@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.game_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover !important;
    opacity: 0;
    transition: opacity .5s;
}

.game_overlay_active {
    opacity: 0.5;
}