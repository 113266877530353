@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.admin_reset_password {
    @include flex(column);
    width: 100%;
    .reset_password_panel {
        position: relative;
        width: 1000px;
        padding: 10px;
        background: $mainBackground;
        .reset_password_header {
            color: $titleColor;
            font-size: 20px;
        }
        .reset_password_content {
            @include flex(column, flex-start);
            width: 100%;
            .admin_password_input_container {
                position: relative;
                img {
                    position: absolute;
                    right: 5px;
                    width: 30px;
                    height: 30px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .admin_btn_container {
                @include flex(row, flex-end);
                button {
                    width: 110px;
                }
            }
        }
    }
}