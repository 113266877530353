@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_view_games {
    width: 100%;
    min-height: 360px;
    .admin_view_games_games_container {
        width: 100%;
        background: $white;
        border-radius: 6px;
        margin-bottom: 20px;
    }
}