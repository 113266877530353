@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_edit_home_video {
    width: 100%;
    margin-bottom: 20px;
    .err_message {
        background: $errorBackground;
        padding: 5px;
        border: 1px solid $errorBorder;
        color: $errorText !important;
        border-radius: 6px;
    }
    .err_input {
        border: 2px solid $errorText;
        &:focus {
            outline-color: $errorText;
        }
    }
    .input {
        border: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    }
}