@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.navbar {
    @include flex(column);
    width: 100%;
    z-index: 3;
    margin-bottom: 60px;
    .content_wrapper {
        @include flex(column);
        width: 100%;
        height: 51px;
        background: $mainBackground;
        border-bottom: 1px solid $mainBorder;
        box-shadow: 0 0 30px 0 #0f0f0f;
        .logo_ul_container {
            @include flex(row, space-between);
            width: 1000px;
            .ul_social_container {
                @include flex(column, center, flex-end);
                position: relative;
            }
            ul {
                @include flex(row);
                margin-left: 300px;
                margin-bottom: 0;
                .nav_btn {
                    @include flex(column);
                    height: 49px;
                    text-align: center;
                    padding: 10px;
                    &:nth-child(2) {
                        margin: 0 5px;
                    }
                    a {
                        color: $subContentColor;
                        font-size: 26px;
                        &:hover {
                            color: $white;
                        }
                    }
                }
                .nav_btn_active {
                    background: $btnPrimaryBackground;
                    a {
                        color: $white;
                    }
                }
            }
            .social_links {
                @include flex(row);
                position: absolute;
                top: 48px;
                border-radius: 0 0 5px 5px;
                padding: 2px 5px;
                background: $mainBackground;
                color: $subContentColor;
                font-size: 16px;
                border: 1px solid $mainBorder;
                border-top: none;
                span {
                    margin-right: 4px;
                }
            }
        }
    }
    .logo {
        background: url("../../Styles/Images/logo.png");
        margin-top: 45px;
        width: 377px;
        height: 69px;
        display: inline-block;
    }
}