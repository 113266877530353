@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.admin_new {
    @include flex(column);
    min-width: 1000px;
    .admin_new_panel {
        @include flex(column, center, flex-start);
        width: 1000px;
        padding: 10px;
        background: $mainBackground;
        .admin_new_header {
            margin-bottom: 10px;
            h3 {
                color: $titleColor;
            }
        }
        .new_user_content {
            @include flex(column, center, flex-start);
            width: 950px;
            z-index: 2;
            .admin_new_input_container {
                @include flex(column, center, flex-start);
                width: 100%;
                margin-bottom: 10px;
                p {
                    color: $contentColor;
                }
                input {
                    width: 100%;
                    height: 30px;
                    border-radius: 6px;
                    padding: 5px;
                }
                .admin_new_password_input {
                    @include flex(row);
                    position: relative;
                    width: 100%;
                    img {
                        position: absolute;
                        right: 5px;
                        width: 30px;
                        height: 30px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
            .admin_new_btn_container {
                @include flex(row, flex-end);
                width: 100%;
                button {
                    color: $black;
                    width: 100px;
                    background: $white;
                    padding: 10px;
                    border-radius: 6px;
                    outline: none;
                    &:hover {
                        cursor: pointer;
                        background: $offWhite;
                    }
                }
            }
        }
    }
}