@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_add_home_social {
    width: 100%;
    height: 360px;
    h4 {
        color: $titleColor;
        margin-bottom: 20px;
    }
    .admin_preview_container {
        @include flex(column);
    }
    .admin_add_social_preview {
        @include flex(row, flex-start);
        border: 1px solid $mainBorder;
        width: 120px;
        height: 25px;
        padding: 2px 5px;
        p {
            color: $contentColor;
            margin-right: 5px;
        }
        .logo_preview {
            width: 17px;
            height: 15px;
            background-size: 100% 100% !important;
        }
    }
    .admin_btn_container {
        margin-top: 60px;
    }
}