@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.team {
    @include flex(column);
    width: 100%;
    .team_panel {
        @include flex(column, flex-start, flex-start);
        width: 1000px;
        padding: 15px;
        h2 {
            margin: 0;
            color: $titleColor;
            font-weight: 400;
        }
        p {
            margin: 16px 0;
            text-align: left;
            word-wrap: break-word;
            white-space: pre-wrap;
        }
        .portrait_list {
            @include flex(row, flex-start, flex-start);
            width: 980px;
            margin: 10px 0;
            flex-wrap: wrap;
        }
    }
}