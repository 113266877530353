@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.game {
    position: relative;
    width: 1000px;
    z-index: 6;
    transition: .5s all;
    margin: 5px 0 105px 0;
    border: 1px solid $whiteBorder;
    box-shadow: 0 0 20px -5px #000;
    &:hover {
        cursor: pointer;
        transform: translate3d(0,-5px,0);
        box-shadow: 0 10px 20px -5px $black;
    }
    .collapsed_content {
        @include flex(row, flex-start);
        width: 100%;
        height: 80px;
        .logo {
            margin-left: 10px;
            max-width: 400px;
            max-height: 150px;
        }
    }
    .game_inner_wrapper {
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-top: 81px;
        .extended_content {
            @include flex(row, space-between, flex-end);
            padding: 10px;
            .game_info {
                @include flex(column, flex-start, flex-start);
                height: 315px;
                width: 395px;
                overflow: hidden;
                h2 {
                    margin: 0;
                    font-weight: 400;
                    font-size: 26px;
                    color: $greenTitle;
                    height: 30px;
                }
                h4 {
                    margin: 0 0 10px;
                    font-weight: 400;
                    color: $greyTitle;
                }
                p {
                    padding-right: 50px;
                    font-size: 16px;
                    line-height: 18px;
                    margin: 0 0 10px;
                    color: $contentColor;
                    text-align: left;
                    word-wrap: break-word;
                }
                .links {
                    a {
                        color: $greenTitle;
                    }
                }
            }
            .youtube_container {
                @include flex(column, flex-start);
                width: 560px;
                height: 315px;
                iframe {
                    border: 1px solid $whiteBorder;
                    z-index: 7;
                }
            }
        }
        .game_footer {
            background: linear-gradient(177deg, transparent 50%,$black 100%);
            width: 100%;
            height: 80px;
            position: absolute;
            bottom: 0;
            opacity: .7;
            z-index: 6;
        }
    }
}

.game_retracted {
    height: 80px;
}

.game_extended {
    height: 418px;
    box-shadow: 0 10px 20px -5px #000;
}