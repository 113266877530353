@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_view_home_videos {
    @include flex(column, flex-start);
    width: 100%;
    min-height: 360px;
    .admin_home_videos_videos_container {
        width: 100%;
        background: $white;
        border-radius: 6px;
        margin-bottom: 20px;
    }
}