@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_add_team_member {
    width: 100%;
    margin-bottom: 20px;
    .admin_add_team_member_preview {
        @include flex(row);
        width: 323px;
        height: 132px;
        .team_member_img_preview {
            img {
                width: 120px;
                height: 120px;
                border: 1px solid $whiteBorder;
                margin-right: 10px;
            }
        }
        .team_member_info_preview {
            @include flex(column, flex-start, flex-start);
            width: 201px;
            line-height: 95%;
            span {
                color: $contentColor;
            }
            p {
                color: $contentColor;
                margin-bottom: 5px;
                font-size: 14px;
            }
            #name {
                font-size: 14px;
            }
            #title {
                color: $subContentColor;
                font-size: 13px;
            }
            .name_title_preivew {
                @include flex(column, flex-start, flex-start);
                margin-bottom: 20px;
            }
        }
    }
}