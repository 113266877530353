@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.admin_main_change_password {
    width: 100%;
    h4 {
        color: $titleColor;
        margin-bottom: 10px;
        text-align: center;
    }
    .admin_btn_container {
        @include flex(row, flex-end);
        button {
            width: 120px;
        }
    }
}