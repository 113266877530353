@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_view_team {
    @include flex(column);
    width: 100%;
    min-height: 360px;
    .admin_view_team_description_form_container {
        @include flex(column, center, flex-start);
        width: 100%;
        margin-bottom: 20px;
        textarea {
            width: 100%;
            height: 150px;
            border-radius: 10px;
            padding: 10px;
            margin-bottom: 10px;
        }
        .admin_view_team_update_description_btn_container {
            @include flex(column, center, flex-end);
            width: 100%;
        }
    }
    .admin_view_team_members_container {
        width: 100%;
        .admin_view_team_team_container {
            width: 100%;
            background: $white;
            border-radius: 6px;
            margin-bottom: 20px;
        }
    }
}