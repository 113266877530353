@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_preview_home_video {
    @include flex(column);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $blackTransparent;
    .admin_preview_home_video_panel {
        @include flex(column);
        position: relative;
        width: 1000px;
        height: 500px;
        background: $mainBackground;
        .admin_preview_home_video_reload {
            @include flex(row);
            width: 100%;
            margin-bottom: 10px;
            p {
                margin-right: 10px;
                color: $titleColor;
            }
            button {
                background: $white;
                border: none;
                width: 85px;
                height: 30px;
                border-radius: 6px;
                &:hover {
                    cursor: pointer;
                    background: $offWhite;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .admin_preview_home_video_video_container {
            position: relative;
            width: 1000px;
            height: 450px;
            video {
                width: 100%;
                height: 450px;
            }
            .admin_preview_home_video_overlay {
                position: absolute;
                background-image: url("../../../../../../Styles/Images/Home/overlay.png");
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
        .preview_home_video_logo {
            position: absolute;
            max-width: 600px;
            max-height: 300px;
        }
        .preview_home_video_icon_greyed {
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: 36px;
            height: 36px;
            background-size: 100% 100% !important;
            &:hover {
                cursor: pointer;
            }
        }
        .preview_home_video_icon_active {
            width: 36px;
            height: 36px;
            opacity: 0;
            transition: opacity .5s;
            &:hover {
                opacity: 1;
            }
        }
    }
}

.preview_show {
    display: flex;
}

.preview_hide {
    display: none;
}