$white: #FFF;
$grey: #555555;
$black: rgb(0,0,0);
$errorText: #a94442;
$errorBackground: #f2dede;
$errorBorder: #ebccd1;
$panelBorder: #ddd;
$formFocus:  #66afe9;
$formShadow: rgba(102,175,233,.6);
$anchorNormalColor: #87c2ed;
$anchorDarkerColor: #144067;
$spinnerColor: #999;
$spinnerBottomColor: #555;
$btnPrimaryBackground: #282C44;
$btnPrimaryBorder: #2e6da4;
$btnPrimaryHover: #286090;
$mainBackground: #252525;
$mainBorder: #484848;
$titleColor: #CFE6FF;
$contentColor: #CFCFCF;
$subContentColor: #959595;
$lightGrey: #847784;
$green: #00ce5f;
$offWhite: #dfdfdfec;
$whiteTransparent: rgba(255, 255, 255, 0.5);
$blackTransparent: rgba(0,0,0,.4);
$greenTitle: #eaffd2;
$greyTitle: #aaa;
$whiteBorder: #ddd;