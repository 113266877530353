@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.admin_users {
    width: 100%;
    margin-bottom: 20px;
    h4 {
        color: $titleColor;
        margin-bottom: 10px;
    }
    .email_input_error {
        border: 1px solid $errorText;
    }
    .email_warning {
        width: 100%;
        background: $errorBackground;
        color: $errorText;
        border: 1px solid $errorBorder;
        margin-bottom: 20px;
        border-radius: 6px;
        p {
            margin-bottom: 0;
            padding: 5px;
        }
    }
    .admin_password_input {
        position: relative;
        @include flex(row);
        margin-bottom: 20px;
        width: 100%;
        input {
            margin-right: 5px;
        }
        img {
            position: absolute;
            right: 10px;
            width: 30px;
            height: 30px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .admin_users_btn_container {
        @include flex(row, flex-end);
        width: 100%;
        button {
            background: $white;
            border: none;
            width: 85px;
            height: 30px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
                background-color: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
    }
}