@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.game_icon_grey {
    width: 36px;
    height: 36px;
}

.game_icon_active {
    width: 36px;
    height: 36px;
    opacity: 0;
    transition: opacity .5s;
    &:hover {
        cursor: pointer;
        opacity: 1;
    }
}

.icon_active {
    opacity: 1;
}