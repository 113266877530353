@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.navbar_social {
    .social_img {
        margin-right: 4px;
        margin-bottom: 4px;
        width: 17px;
        height: 15px;
        background-size: 100% 100% !important;
    }
}