@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.admin_main {
    @include flex(column);
    min-width: 1000px;
    .admin_new_user_content {
        width: 100%;
        p {
            margin-bottom: 20px;
        }
        .admin_new_user_sign_out {
            background: $btnPrimaryBackground;
            padding: 5px;
            border-radius: 6px;
            a {
                display: inline-block;
                color: $black;
                width: 85px;
                text-decoration: none;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    
    .admin_panel {
        position: relative;
        z-index: 1;
        text-align: left;
        margin-bottom: 100px;
        width: 1000px;
        max-width: 1000px;
        background: $mainBackground;
        margin: 0;
        margin-bottom: 50px;
        .admin_main_header {
            color: $titleColor;
            padding: 10px;
        }
        .admin_panel_content {
            @include flex(row, flex-start, flex-start);
            nav {
                width: 15%;
                margin-left: 10px;
                color: $black;
                margin-top: 10px;
                .admin_nav_btn {
                    padding: 10px;
                    border-radius: 6px;
                    width: 100%;
                    margin-bottom: 5px;
                    color: $titleColor;
                    &:hover {
                        cursor: pointer;
                        background: $btnPrimaryBackground;
                    }
                }
                .admin_nav_sign_out {
                    padding: 10px;
                    border-radius: 6px;
                    width: 100%;
                    margin-bottom: 5px;
                    a {
                        display: block;
                        color: $titleColor;
                        width: 105%;
                        &:hover {
                            cursor: pointer;
                            background: $btnPrimaryBackground;
                        }
                    }
                }
                .admin_nav_active {
                    background: $btnPrimaryBackground;
                }
            }
            .admin_main_content {
                width: 75%;
                padding-top: 20px;
                padding-left: 20px;
                border-left: 1px solid $white;
            }
        }
    }
}

.admin_spinner_container {
    position: relative;
    font-size: 13px;
    transition: all 1s;
}

.admin_form_preview_container {
    @include flex(row, space-between);
    width: 100%;
    .admin_form_container {
        width: 50% !important;
    }
    .admin_preview_container {
        @include flex(column);
        width: 50%;
    }
}

.admin_form_container {
    @include flex(column, flex-start);
    width: 100%;
    h4 {
        width: 100%;
    }
    .admin_input_container {
        width: 100%;
        margin-bottom: 10px;
        p {
            color: $contentColor;
            text-align: start;
            margin-bottom: 5px;
        }
        input {
            width: 100%;
            height: 30px;
            border-radius: 6px;
            padding: 5px;
        }
        textarea {
            width: 100%;
            border-radius: 6px;
            padding: 5px;
        }
        select {
            width: 50%;
            height: 30px;
            padding: 5px;
            border-radius: 6px;
        }
        .admin_char_limit_container {
            @include flex(row, space-between);
            width: 100%;
        }
        .admin_file_upload_container {
            @include flex(row, flex-start);
            background: $white;
            width: 100%;
            padding: 5px;
            border-radius: 6px;
            margin-bottom: 10px;
            p {
                color: $grey;
                margin: 0;
            }
            input {
                width: 80px;
                padding: 0;
                border-radius: 0;
                &::-webkit-file-upload-button {
                    visibility: hidden;
                }
                &::-moz-file-upload-button {
                    visibility: hidden;
                }
                &::before {
                    content: 'Browse...';
                    display: inline-block;
                    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
                    color: $black;
                    border: 1px solid $black;
                    padding: 5px 8px;
                    outline: none;
                }
                &:hover {
                    cursor: pointer;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .admin_order_change_container {
            @include flex(row, flex-start, center);
            width: 100%;
            input {
                width: 50%;
            }
            button {
                background: $white;
                border: none;
                width: 100px;
                height: 30px;
                border-radius: 6px;
                margin-left: 5px;
                &:hover {
                    cursor: pointer;
                    background-color: $offWhite;
                }
                &:focus {
                    outline: none;
                }
            }
            .admin_store_order_reset_cancel_btn {
                width: 25px;
                margin-right: 5px;
                margin-left: 0;
            }
        }
    }
}

.admin_btn_container {
    @include flex(row, space-between);
    width: 100%;
    button {
        background: $white;
        border: none;
        width: 85px;
        height: 30px;
        border-radius: 6px;
        &:hover {
            cursor: pointer;
            background: $offWhite;
        }
        &:focus {
            outline: none;
        }
    }
    .admin_two_btn {
        :first-child {
            margin-right: 5px;
        }
    }
}

.admin_previous_next_container {
    @include flex(row, space-between);
    width: 100%;
    margin-bottom: 10px;
    button {
        background: $white;
        border: none;
        border-radius: 15px;
        padding: 10px 15px;
        font-size: 120%;
        color: $green;
        &:focus {
            outline: none;
        }
        &:disabled {
            color: $lightGrey;
        }
        &:hover {
            cursor: pointer;
            background: $offWhite;
        }
    }
}

.admin_list_item {
    @include flex(row, space-between);
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $black;
    p {
        color: $black;
    }
    .admin_list_item_btn_container {
        button {
            background: $white;
            margin-right: 5px;
            border: 1px solid $black;
            border-radius: 6px;
            padding: 2px 5px;
            width: 50px;
            height: 20px;
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
    }
}

.admin_password_input_container {
    position: relative;
    width: 100%;
    img {
        position: absolute;
        right: 5px;
        width: 30px;
        height: 30px;
        &:hover {
            cursor: pointer;
        }
    }
}

.admin_view_page_info_container {
    @include flex(row, space-between);
    width: 100%;
    width: 100%;
    background: $whiteTransparent;
    border: 1px solid $white;
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 20px;
    h4 {
        margin: 0;
        color: $grey;
    }
    button {
        background: $white;
        border: none;
        padding: 8px 15px;
        border-radius: 3px;
        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
            background: $offWhite;
        }
    }
}