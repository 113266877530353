@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.portrait {
    @include flex(row, flex-start, flex-start);
    height: 132px;
    width: 33%;
    margin-top: 20px;
    overflow: hidden;
    img {
        border: 1px solid $whiteBorder;
        margin-right: 10px;
        width: 120px;
        height: 120px;
    }
    .description {
        line-height: 95%;
        text-align: left;
        .name {
            font-weight: 700;
            font-size: 14px;
        }
        .title {
            font-style: italic;
            font-size: 13px;
            color: $subContentColor;
        }
        .facts {
            line-height: 20px;
            font-size: 14px;
            p {
                margin-bottom: 0;
            }
        }
    }
}