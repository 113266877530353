@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.home {
    @include flex(column, flex-start);
    width: 100%;
    .home_banner_container {
        position: relative;
        width: 1000px;
        height: 450px;
        padding: 0;
        overflow: hidden;
        .overlay {
            position: absolute;
            background-image: url("../../Styles/Images/Home/overlay.png");
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
        .game_icons_container {
            @include flex(row);
            position: absolute;
            bottom: 10px;
            left: 10px;
            z-index: 6;
        }
    }
    .home_info_container {
        @include flex(row, space-between);
        width: 1000px;
        .home_box {
            @include flex(column, flex-start, flex-start);
            padding: 0;
            height: 300px;
            overflow: hidden;
            img {
                margin: 5px;
            }
            h2 {
                margin: 5px 5px 5px 0;
            }
            hr {
                margin: 0;
            }
            p {
                padding: 10px;
                margin: 0;
                font-size: 14px;
                line-height: 16px;
                text-align: left;
            }
            .home_box_title {
                @include flex(row, flex-start);
                height: 38px;
            }
            .twitter_wrapper {
                padding: 5px 5px 0 5px;;
            }
        }
        .about_container {
            width: 360px;
        }
        .latest_tweets_container {
            width: 350px;
        }
        .contact_container {
            width: 240px;
            p {
                line-height: 18px;
                a {
                    color: $anchorNormalColor;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.panel {
    width: 100%;
    min-height: 200px;
    background-color: #252525;
    border: 1px solid #484848;
    border-radius: 5px;
    background-repeat: repeat-x;
    box-shadow: 0 0 30px -5px #0f0f0f;
    color: $contentColor;
    padding: 10px;
    margin-bottom: 50px;
    h1 {
        margin: 0;
        color: $titleColor;
        font-weight: 400;
        font-size: 2em;
    }
    h2 {
        margin: 0;
        color: $titleColor;
        font-weight: 400;
        font-size: 1.5em;
    }
    h3 {
        margin: 0;
        color: $titleColor;
        font-weight: 400;
    }
    h4 {
        margin: 0;
        color: $titleColor;
        font-weight: 400;
    }
    h5 {
        margin: 0;
        color: $titleColor;
        font-weight: 400;
    }
    hr {
        border: none;
        border-top: 1px solid $mainBorder;
        float: left;
        width: 100%;
    }
}