.app {
    width: 100%;
    .background_cover {
        background: linear-gradient(to bottom,rgba(79,142,220,0.46) 0%,transparent 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 800px;
        right: 0;
        max-height: 100%;
        z-index: -1;
    }
}