@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.legal {
    @include flex(column);
    width: 100%;
    position: relative;
    p {
        text-align: left;
        margin: 16px 0;
    }
    .terms_conditions {
        @include flex(column, center, flex-start);
        width: 1000px;
    }
    .privacy_policy {
        @include flex(column, center, flex-start);
        width: 1000px;
    }
    .ol_a, .ol_i, .ul_bullet {
        padding-inline-start: 40px;
    }
    .ol_a {
        list-style-type: lower-alpha;
    }
    .ol_i {
        list-style-type: lower-roman;
    }
    .ul_bullet {
        list-style-type: disc;
        p {
            margin: 0;
        }
    }
}