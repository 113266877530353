@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_preview_game {
    @include flex(column);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $blackTransparent;
    .err_message_container {
        height: 32px;
        width: 1000px;
        margin-bottom: 30px;
        padding: 5px;
        background: $errorBackground;
        border: 1px solid $errorBorder;
        border-radius: 6px;
        p {
            color: $errorText;
        }
    }
    .game_overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover !important;
        opacity: 0.5;
    }
    .game_container {
        @include flex(column, flex-start, flex-start);
        position: relative;
        width: 1000px;
        height: 418px;
        background-size: 100% 100% !important;
        border: 1px solid $whiteBorder;
        z-index: 6;
        .logo_container {
            @include flex(row, flex-start);
            width: 1000px;
            height: 80px;
            .logo {
                max-width: 400px;
                max-height: 150px;
            }
        }
        .info_youtube_container {
            @include flex(row);
            width: 100%;
            height: 315px;
            position: absolute;
            bottom: 5px;
            .info_container {
                width: 40%;
                padding: 5px;
                margin-right: 10px;
                h2 {
                    margin: 0;
                    font-weight: 400;
                    font-size: 26px;
                    color: $greenTitle;
                }
                h4 {
                    margin: 0 0 10px;
                    font-weight: 400;
                    color: $greyTitle
                }
                .description_container {
                    height: 250px;
                    overflow: hidden;
                    p {
                        color: $contentColor;
                        margin-bottom: 10px;
                    }

                }
            }
            .youtube_container {
                width: 55%;
                iframe {
                    border: 1px solid $whiteBorder;
                }
            }
        }
    }
}

.preview_show {
    display: flex;
}

.preview_hide {
    display: none;
}