@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.admin_home_info {
    width: 100%;
    margin-bottom: 20px;
    h4 {
        color: $titleColor;
        margin-bottom: 10px;
    }
    .admin_btn_container {
        @include flex(row, flex-end);
        p {
            color: $titleColor;
            margin-right: 10px;
        }
        #admin_home_info_update_twitter_user_btn {
            width: 150px;
            margin-bottom: 20px;
        }
        #admin_home_info_refresh_tweets_btn {
            width: 120px;
        }
    }
    .email_input {
        border: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    }
    .email_err_input {
        border: 2px solid $errorText;
        &:focus {
            outline-color: $errorText;
        }
    }
    .email_err_msg {
        background: $errorBackground;
        border: 1px solid $errorBorder;
        color: $errorText !important;
        padding: 5px;
        border-radius: 6px;
    }
}